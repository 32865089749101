<template>
  <div class="profile-data-wrapper financial-graphs wht" v-show="!loading">
    <div class="profile-options">
      <div class="row">
        <div class="col">
          <router-link class="btn primary-btn" :to="{ name: 'FinancialGraphs' }"
            ><font-awesome-icon :icon="['fas', 'arrow-left']"
          /></router-link>
        </div>
        <div class="col flex-grow-1 d-flex justify-content-end">
          <Select2
            id="filter-period"
            :options="filters.period.options"
            v-model="filters.period.value"
            class="select2-box"
            :resetOptions="false"
            @select="getWHT()"
          />
        </div>
        <div class="col">
          <Select2
            id="filter-connect-types"
            v-model="filters.profile.value"
            :options="filters.profile.options"
            class="select2-box"
            :resetOptions="false"
            :placeholder="`Connect Type`"
            @select="getWHT()"
          />
        </div>
      </div>
    </div>
    <div class="row grid-1">
      <div class="col">
        <div class="connects-chart">
          <h2 class="section-heading text-transform-uppercase">
            WHT Graph ({{filters.period.value}})
          </h2>
          <template
            v-if="
              !connectData ||
              connectData.reduce((partialSum, a) => partialSum + a.z, 0) === 0
            "
          >
            <div
              class="
                no-data-wrap
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <img src="@/assets/images/no-graph.png" alt="no-graph" />
              <div class="desc">No data<br />to show</div>
            </div>
          </template>
          <template v-else>
            <chart-js
              :type="`bar`"
              :datasets="connectChart.datasets"
              :labels="connectChart.labels"
              :options="connectChart.options"
            />
          </template>
        </div>
      </div>
      <!-- <div class="col">
        <div class="connects-chart">
          <h2 class="section-heading text-transform-uppercase">
            WHT Graph (Contract Wise)
          </h2>
          <template
            v-if="
              !connectData ||
              connectData.reduce((partialSum, a) => partialSum + a.z, 0) === 0
            "
          >
            <div
              class="
                no-data-wrap
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <img src="@/assets/images/no-graph.png" alt="no-graph" />
              <div class="desc">No data<br />to show</div>
            </div>
          </template>
          <template v-else>
            <chart-js
              :type="`bar`"
              :datasets="connectChart.datasets"
              :labels="connectChart.labels"
              :options="connectChart.options"
            />
          </template>
        </div>
      </div> -->
    </div>
    <div class="row grid-1">
      <div class="col">
        <div class="custom-table no-arrows">
          <table id="datatable">
            <thead>
              <tr>
                <th>Time Period</th>

                <!-- <th>Profile</th> -->
                <th>WHT($)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(connect, index) in connectData" :key="index">
                <td>{{ connect.x }}</td>
                <!-- <td>{{ connect.y.ProfileName }}</td> -->
                <td>{{ Math.round(connect.y) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="profile-footer d-flex justify-content-end">
      <button class="primary-btn" type="button" @click="downloadCsv()">
        <font-awesome-icon :icon="['fa', 'download']" /> Download
      </button>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import ChartJs from "../components/ChartJs.vue";
import financialWHTService from "../services/financialWHT.service";
import common from "../mixins/common";
import dataLabels from "chartjs-plugin-datalabels";

export default {
  components: { Select2, FontAwesomeIcon, ChartJs },
  mixins: [common],
  data() {
    return {
      loading: false,
      filters: {
        period: {
          options: ["Monthly", "Annually"],
          value: "Monthly",
        },
        profile: {
          options: [],
          value: "All",
        },
      },
      connectData: [],
      connectChart: {
        labels: [],
        datasets: [
          {
            label: "WHT",
            data: [],
            borderColor: "#6fda45",
            backgroundColor: "#6fda45",
          },
        ],
        plugins: [dataLabels],
        options: {
          layout: {
            padding: {
              top: 30,
              bottom: 30,
            },
          },
          indexAxis: "y",
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: false,
            title: {
              display: true,
              text: "",
            },
            datalabels: {
              backgroundColor: "transparent",
              borderRadius: 4,
              color: "#fff",
              font: {
                weight: "400",
                size: '16px "Montserrat", sans-serif',
              },
              padding: 0,
              formatter: function (value) {
                return `${Math.round(value)}`;
              },
            },
          },
        },
      },
      sideModal: {
        connect: {
          add: {
            show: false,
          },
        },
      },
    };
  },
  async mounted() {
    await this.getWHT();
    this.loading = false;
  },
  methods: {
    async getWHT() {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const response = await financialWHTService.get(
          vm.filters.period.value,
          vm.filters.profile.value
        );
        vm.connectData = response.wht.slice().reverse();
        vm.filters.profile.options = response.profiles;
        vm.connectChart.datasets[0].data = response.wht_dts.slice().reverse();
        vm.connectChart.labels = response.wht_lbs.slice().reverse();
        vm.connectChart.options.plugins.title.text =
          vm.filters.profile.value + " (" + vm.filters.period.value + ")";
        //vm.filters.client.value = response.filters.client;
        $("#datatable").DataTable().destroy();

        vm.$nextTick(function () {
          $("#datatable").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "csv",
                filename: "Withholding Tax : "+vm.filters.profile.value + " (" + vm.filters.period.value + ")",
                extension: ".csv",
              },
            ],
            iDisplayLength: 25,
            bLengthChange: false,
            order: [[0, "desc"]],
            ordering: false,
            info: false,
            fnDrawCallback: function (oSettings) {
              if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
              } else {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
              }
            },
          });
          $(".dt-buttons, .dataTables_filter").hide();
        });
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.sideModal.connect.add.show = false;
      }, 300);
    },
    async openSideModal(action) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      if (
        vm.userPermissions &&
        vm.userPermissions.financial_graphs &&
        vm.userPermissions.financial_graphs.length > 0 &&
        vm.userPermissions.financial_graphs.includes("View")
      ) {
        if (
          action === "addConnect" &&
          vm.userPermissions.financial_graphs.includes("Add")
        ) {
          vm.sideModal.connect.add.show = true;
        }
        setTimeout(() => {
          document.body.classList.add("lmodal-open");
        }, 300);
      }
    },
  },
};
</script>
